import { generalLinks } from './generalLinks';
import { ViewCaseNeuro } from '../../../../pages/user/viewCaseNeuro/ViewCase';
import { ViewCaseLung } from '../../../../pages/user/viewCaseLung/ViewCase';
import { MyCases } from '../../../../pages/user/myCases/MyCases';
import { OtherCases } from '../../../../pages/user/otherCases/OtherCases';
import { Profile } from '../../../../pages/user/profile/Profile';
import { MyReckoning } from '../../../../pages/user/myReckoning/MyReckoning';
import { MyAcceptedCases } from '../../../../pages/user/myReckoning/myAcceptedCases/MyAcceptedCases';
import { SelectForm } from '../../../../pages/user/profile/selectForm/SelectForm';
import { ContractForm } from '../../../../pages/user/profile/form/contract/ContractForm';
import { InvoiceForm } from '../../../../pages/user/profile/form/invoice/InvoiceForm';
import { SummaryFormInvoice } from '../../../../pages/user/profile/summaryForm/invoice/SummaryFormInvoice';
import { SummaryFormContract } from '../../../../pages/user/profile/summaryForm/contract/SummaryFormContract';
import { Documents } from '../../../../pages/user/profile/documents/Documents';
import { Home } from '../../../../pages/user/home/Home';
import { DraftCaseNeuro } from '../../../../pages/user/draftCaseNeuro/DraftCaseNeuro';
import { DraftCaseLung } from '../../../../pages/user/draftCaseLung/DraftCaseLung';
import { Page404 } from '../../../../pages/page404/Page404';

export const onlyUserLinks = [
  {
    path: '/',
    element: <Home />
  },
  // {
  //   path: '/cases/neuro/new',
  //   element: <DraftCaseNeuro isReset={true} />
  // },
  {
    path: '/cases/neuro/new',
    element: <Page404 />
  },
  {
    path: '/cases/neuro/:id/edit',
    element: <DraftCaseNeuro />
  },
  // {
  //   path: '/cases/lung/new',
  //   element: <DraftCaseLung isReset={true} />
  // },
  {
    path: '/cases/lung/new',
    element: <Page404 />
  },
  {
    path: '/cases/lung/:id/edit',
    element: <DraftCaseLung />
  },
  {
    path: '/cases/neuro/:id',
    element: <ViewCaseNeuro />
  },
  {
    path: '/cases/lung/:id',
    element: <ViewCaseLung />
  },
  {
    path: '/cases/my-cases',
    element: <MyCases />
  },
  {
    path: '/cases/other-cases',
    element: <OtherCases />
  },
  {
    path: '/profile',
    element: <Profile />
  },
  {
    path: '/profile/form',
    element: <SelectForm />
  },
  {
    path: '/profile/form/invoice',
    element: <InvoiceForm />
  },
  {
    path: '/profile/form/contract',
    element: <ContractForm />
  },
  {
    path: '/profile/form/invoice/summary',
    element: <SummaryFormInvoice />
  },
  {
    path: '/profile/form/contract/summary',
    element: <SummaryFormContract />
  },
  {
    path: '/profile/form/documents',
    element: <Documents />
  },
  {
    path: '/my-reckoning',
    element: <MyReckoning />
  },
  {
    path: '/my-reckoning/my-accepted-cases',
    element: <MyAcceptedCases />
  }
];

export const userLinks = onlyUserLinks.concat(generalLinks);
