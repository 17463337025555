import { useEffect, useState } from 'react';
import {
  useAuthStore,
  useDraftCaseStore,
  useProgressBarLungStore,
  useProgressBarNeuroStore
} from '../../../../../store/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { generateAreaLink, generateLink } from '../../../../../utils';
import { RoleCategory } from '../../../../../constants/projectUsers';
import { useModal } from '../../../modal/useModal.vm';
import { AddCaseActionStatus } from '../../../../../constants/addCaseStatus';
import { getPathType } from '../../../../../utils/switchArea';
import { ModalType } from '../../../../../constants/modalType';

export const useNavigateButtons = () => {
  const navigate = useNavigate();
  const { handleModal, isModalOpened, closeModal, modalType } = useModal();
  const progressBarNeuroStore = useProgressBarNeuroStore();
  const progressBarLungStore = useProgressBarLungStore();
  const authStore = useAuthStore();
  const { areas } = authStore;
  const [selectValue, setSelectedValue] = useState(-1);
  const [isError, setError] = useState(false);
  const location = useLocation();
  const type = getPathType(location.pathname);
  const draftCaseStore = useDraftCaseStore();

  const addCase = () => {
    if (areas)
      if (areas.length === 1) {
        const [mainArea] = areas;
        const { shortName } = mainArea;

        if (shortName === 'neuro' || shortName === 'lung') {
          handleModal(ModalType.MODAL_BLOCKED);
          progressBarNeuroStore.resetProgressBar();
        } else {
          const newLink = generateAreaLink(shortName, 'add');
          if (newLink) {
            navigate(generateLink(RoleCategory.USER, newLink));
            if (type === 'lung') {
              progressBarLungStore.resetProgressBar();
            } else if (type === 'neuro') {
              progressBarNeuroStore.resetProgressBar();
            }
          }
        }
      } else {
        handleModal(ModalType.SELECT_AREA);
      }
  };

  // const addCase = () => {
  //   if (areas)
  //     if (areas.length === 1) {
  //       const [mainArea] = areas;
  //       const { shortName } = mainArea;
  //       const newLink = generateAreaLink(shortName, 'add');
  //       if (newLink) {
  //         navigate(generateLink(RoleCategory.USER, newLink));
  //         if (type === 'lung') {
  //           progressBarLungStore.resetProgressBar();
  //         } else if (type === 'neuro') {
  //           progressBarNeuroStore.resetProgressBar();
  //         }
  //       }
  //     } else {
  //       handleModal(ModalType.SELECT_AREA);
  //     }
  // };

  const selectedArea = areas?.find(el => el.id === selectValue)?.shortName;
  const selectedAreaStatus = areas?.find(el => el.id === selectValue)?.addCaseActionStatus;

  // const addCaseFurther = () => {
  //   if (areas) {

  //     if (selectedArea) {
  //       const newLink = generateAreaLink(selectedArea, 'add');
  //       if (newLink) {
  //         navigate(generateLink(RoleCategory.USER, newLink));
  //         if (type === 'lung') {
  //           progressBarLungStore.resetProgressBar();
  //         } else if (type === 'neuro') {
  //           progressBarNeuroStore.resetProgressBar();
  //         }
  //         draftCaseStore.resetDraftCaseLung();
  //         draftCaseStore.resetDraftCaseNeuro();

  //         closeModal();
  //       }
  //     }
  //   }
  // };

  const addCaseFurther = () => {
    if (areas) {
      if (selectedArea === 'neuro' || selectedArea === 'lung') {
        handleModal(ModalType.MODAL_BLOCKED);
        progressBarNeuroStore.resetProgressBar();
      } else if (selectedArea) {
        const newLink = generateAreaLink(selectedArea, 'add');
        if (newLink) {
          navigate(generateLink(RoleCategory.USER, newLink));
          if (type === 'lung') {
            progressBarLungStore.resetProgressBar();
          } //else if (type === 'neuro') {
          // progressBarNeuroStore.resetProgressBar();
          // }
          draftCaseStore.resetDraftCaseLung();
          draftCaseStore.resetDraftCaseNeuro();

          closeModal();
        }
      }
    }
  };

  useEffect(() => {
    if (selectedAreaStatus === AddCaseActionStatus.BLOCKED) {
      setError(true);
    } else {
      setError(false);
    }
  }, [selectValue, selectedAreaStatus]);

  const selectOptions =
    areas &&
    areas.map(el => ({
      id: el.id,
      name: el.fullName
    }));

  return {
    addCase,
    isModalOpened,
    closeModal,
    selectOptions,
    setSelectedValue,
    addCaseFurther,
    selectValue,
    isError,
    modalType
  };
};
